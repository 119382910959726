import { EngagementHorizontalData, EngagementLineData } from "features/Library/Pdfs/types/state";
import {
  EmailCounts,
  EngagementCounts,
  EmailDetails,
  FlowCards,
  FlowDetails,
  AssetActivityCounts,
  VideoActivityCounts,
  VideoDetails,
  AssessmentActivityCounts,
  AssessmentEngagement,
  AssessmentDetails,
  AssessmentCards,
  SurveyActivityCounts,
  SurveyEngagement,
  SurveyDetails,
  SurveyCards,
  PdfDetails,
  VideoEngagement,
} from "../types/content";
import { CardReportingItem } from "components/cards/CardReporting/CardReporting";
import {
  completes,
  completesColor,
  roundToTwoDigits,
  starts,
  startsColor,
} from "features/Library/Common/utils/performanceUtils";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import { EventsTableEntity } from "../Content/sections/Events/EventsReport";

export const mockEmailDensity: EmailCounts[] = [
  {
    Date: "2023-07-12",
    Clicks: 3,
    Opens: 26,
    Sends: 65,
    CumulativeClicks: 3,
    CumulativeOpens: 26,
    CumulativeSends: 65,
  },
  {
    Date: "2023-07-13",
    Clicks: 91,
    Opens: 61,
    Sends: 38,
    CumulativeClicks: 94,
    CumulativeOpens: 87,
    CumulativeSends: 103,
  },
  {
    Date: "2023-07-14",
    Clicks: 34,
    Opens: 51,
    Sends: 67,
    CumulativeClicks: 128,
    CumulativeOpens: 138,
    CumulativeSends: 170,
  },
  {
    Date: "2023-07-15",
    Clicks: 67,
    Opens: 52,
    Sends: 34,
    CumulativeClicks: 195,
    CumulativeOpens: 190,
    CumulativeSends: 204,
  },
  {
    Date: "2023-07-16",
    Clicks: 18,
    Opens: 3,
    Sends: 97,
    CumulativeClicks: 213,
    CumulativeOpens: 193,
    CumulativeSends: 301,
  },
  {
    Date: "2023-07-17",
    Clicks: 58,
    Opens: 4,
    Sends: 78,
    CumulativeClicks: 271,
    CumulativeOpens: 197,
    CumulativeSends: 379,
  },
  {
    Date: "2023-07-18",
    Clicks: 21,
    Opens: 22,
    Sends: 38,
    CumulativeClicks: 292,
    CumulativeOpens: 219,
    CumulativeSends: 417,
  },
  {
    Date: "2023-07-19",
    Clicks: 56,
    Opens: 44,
    Sends: 99,
    CumulativeClicks: 348,
    CumulativeOpens: 263,
    CumulativeSends: 516,
  },
  {
    Date: "2023-07-20",
    Clicks: 22,
    Opens: 21,
    Sends: 89,
    CumulativeClicks: 370,
    CumulativeOpens: 284,
    CumulativeSends: 605,
  },
  {
    Date: "2023-07-21",
    Clicks: 74,
    Opens: 80,
    Sends: 94,
    CumulativeClicks: 444,
    CumulativeOpens: 364,
    CumulativeSends: 699,
  },
  {
    Date: "2023-07-22",
    Clicks: 73,
    Opens: 20,
    Sends: 24,
    CumulativeClicks: 517,
    CumulativeOpens: 384,
    CumulativeSends: 723,
  },
  {
    Date: "2023-07-23",
    Clicks: 47,
    Opens: 20,
    Sends: 38,
    CumulativeClicks: 564,
    CumulativeOpens: 404,
    CumulativeSends: 761,
  },
  {
    Date: "2023-07-24",
    Clicks: 38,
    Opens: 58,
    Sends: 67,
    CumulativeClicks: 602,
    CumulativeOpens: 462,
    CumulativeSends: 828,
  },
  {
    Date: "2023-07-25",
    Clicks: 48,
    Opens: 30,
    Sends: 52,
    CumulativeClicks: 650,
    CumulativeOpens: 492,
    CumulativeSends: 880,
  },
  {
    Date: "2023-07-26",
    Clicks: 32,
    Opens: 76,
    Sends: 88,
    CumulativeClicks: 682,
    CumulativeOpens: 568,
    CumulativeSends: 968,
  },
  {
    Date: "2023-07-27",
    Clicks: 67,
    Opens: 75,
    Sends: 35,
    CumulativeClicks: 749,
    CumulativeOpens: 643,
    CumulativeSends: 1003,
  },
  {
    Date: "2023-07-28",
    Clicks: 15,
    Opens: 88,
    Sends: 14,
    CumulativeClicks: 764,
    CumulativeOpens: 731,
    CumulativeSends: 1017,
  },
  {
    Date: "2023-07-29",
    Clicks: 40,
    Opens: 20,
    Sends: 35,
    CumulativeClicks: 804,
    CumulativeOpens: 751,
    CumulativeSends: 1052,
  },
  {
    Date: "2023-07-30",
    Clicks: 45,
    Opens: 38,
    Sends: 8,
    CumulativeClicks: 849,
    CumulativeOpens: 789,
    CumulativeSends: 1060,
  },
  {
    Date: "2023-07-31",
    Clicks: 96,
    Opens: 68,
    Sends: 61,
    CumulativeClicks: 945,
    CumulativeOpens: 857,
    CumulativeSends: 1121,
  },
  {
    Date: "2023-08-01",
    Clicks: 33,
    Opens: 81,
    Sends: 5,
    CumulativeClicks: 978,
    CumulativeOpens: 938,
    CumulativeSends: 1126,
  },
  {
    Date: "2023-08-02",
    Clicks: 33,
    Opens: 81,
    Sends: 5,
    CumulativeClicks: 1011,
    CumulativeOpens: 1019,
    CumulativeSends: 1131,
  },
  {
    Date: "2023-08-03",
    Clicks: 45,
    Opens: 19,
    Sends: 76,
    CumulativeClicks: 1056,
    CumulativeOpens: 1038,
    CumulativeSends: 1207,
  },
  {
    Date: "2023-08-04",
    Clicks: 12,
    Opens: 18,
    Sends: 68,
    CumulativeClicks: 1068,
    CumulativeOpens: 1056,
    CumulativeSends: 1275,
  },
  {
    Date: "2023-08-05",
    Clicks: 61,
    Opens: 37,
    Sends: 80,
    CumulativeClicks: 1129,
    CumulativeOpens: 1093,
    CumulativeSends: 1355,
  },
  {
    Date: "2023-08-06",
    Clicks: 4,
    Opens: 9,
    Sends: 25,
    CumulativeClicks: 1133,
    CumulativeOpens: 1102,
    CumulativeSends: 1380,
  },
  {
    Date: "2023-08-07",
    Clicks: 72,
    Opens: 79,
    Sends: 82,
    CumulativeClicks: 1205,
    CumulativeOpens: 1181,
    CumulativeSends: 1462,
  },
  {
    Date: "2023-08-08",
    Clicks: 1,
    Opens: 6,
    Sends: 85,
    CumulativeClicks: 1206,
    CumulativeOpens: 1187,
    CumulativeSends: 1547,
  },
  {
    Date: "2023-08-09",
    Clicks: 14,
    Opens: 84,
    Sends: 15,
    CumulativeClicks: 1220,
    CumulativeOpens: 1271,
    CumulativeSends: 1562,
  },
  {
    Date: "2023-08-10",
    Clicks: 4,
    Opens: 85,
    Sends: 7,
    CumulativeClicks: 1224,
    CumulativeOpens: 1356,
    CumulativeSends: 1569,
  },
  {
    Date: "2023-08-11",
    Clicks: 7,
    Opens: 48,
    Sends: 68,
    CumulativeClicks: 1231,
    CumulativeOpens: 1404,
    CumulativeSends: 1637,
  },
  {
    Date: "2023-08-12",
    Clicks: 23,
    Opens: 60,
    Sends: 38,
    CumulativeClicks: 1254,
    CumulativeOpens: 1464,
    CumulativeSends: 1675,
  },
];

export const mockEmailEngagement: EngagementCounts = {
  ClickThroughRate: 2,
  ClickToOpen: 59,
  OpenRate: 47,
  AvgSendSize: 67,
  Clicks: 65,
  Opens: 41,
  Sends: 27,
  Customers: 53,
  Emails: 0,
};

export const mockEmptyEmailEngagement: EngagementCounts = {
  ClickThroughRate: 0,
  ClickToOpen: 0,
  OpenRate: 0,
  AvgSendSize: 0,
  Clicks: 0,
  Opens: 0,
  Sends: 0,
  Customers: 0,
  Emails: 0,
};

export const mockEmailDetails: EmailDetails[] = [
  {
    EmailId: 62620,
    EmailTitle: "mockEmailTitle",
    SendSessionId: 3,
    SendSession: "2022-07-02",
    Sends: 3,
    Opens: 2,
    Clicks: 1,
    ClickThroughRate: 0.4,
    ClickToOpenRate: 0.2,
  },
  {
    EmailId: 2,
    EmailTitle: "title2",
    SendSessionId: 51,
    SendSession: "2022-07-11",
    Sends: 61,
    Opens: 76,
    Clicks: 94,
    ClickThroughRate: 0.7,
    ClickToOpenRate: 0.1,
  },
  {
    EmailId: 81,
    EmailTitle: "title1",
    SendSessionId: 28,
    SendSession: "2022-07-02",
    Sends: 87,
    Opens: 71,
    Clicks: 13,
    ClickThroughRate: 0.1,
    ClickToOpenRate: 0.9,
  },
  {
    EmailId: 45,
    EmailTitle: "title2",
    SendSessionId: 13,
    SendSession: "2022-07-11",
    Sends: 61,
    Opens: 76,
    Clicks: 94,
    ClickThroughRate: 0.73,
    ClickToOpenRate: 0.12,
  },
  {
    EmailId: 81,
    EmailTitle: "title1",
    SendSessionId: 25,
    SendSession: "2022-07-02",
    Sends: 87,
    Opens: 71,
    Clicks: 13,
    ClickThroughRate: 0.1,
    ClickToOpenRate: 0.9,
  },
  {
    EmailId: 33,
    EmailTitle: "title2",
    SendSessionId: 6,
    SendSession: "2022-07-11",
    Sends: 61,
    Opens: 76,
    Clicks: 94,
    ClickThroughRate: 0.5,
    ClickToOpenRate: 0.5,
  },
  {
    EmailId: 58,
    EmailTitle: "title1",
    SendSessionId: 88,
    SendSession: "2022-07-02",
    Sends: 87,
    Opens: 71,
    Clicks: 13,
    ClickThroughRate: 0.4,
    ClickToOpenRate: 0.7,
  },
  {
    EmailId: 76,
    EmailTitle: "title2",
    SendSessionId: 2,
    SendSession: "2022-07-11",
    Sends: 61,
    Opens: 76,
    Clicks: 94,
    ClickThroughRate: 0.6,
    ClickToOpenRate: 0.3,
  },
  {
    EmailId: 14,
    EmailTitle: "title1",
    SendSessionId: 62,
    SendSession: "2022-07-02",
    Sends: 55,
    Opens: 32,
    Clicks: 64,
    ClickThroughRate: 0.3,
    ClickToOpenRate: 0.8,
  },
  {
    EmailId: 22,
    EmailTitle: "title2",
    SendSessionId: 20,
    SendSession: "2022-07-11",
    Sends: 62,
    Opens: 93,
    Clicks: 39,
    ClickThroughRate: 0.2,
    ClickToOpenRate: 0.15,
  },
];

export const mockFlowDailyData: AssetActivityCounts[] = [
  {
    Completed: 0,
    CompletedTotal: 0,
    Date: "2023-06-18",
    InProgress: 0,
    InProgressTotal: 0,
    NotStarted: 0,
    Users: 0,
  },
  {
    Completed: 1,
    CompletedTotal: 1,
    Date: "2023-06-19",
    InProgress: 0,
    InProgressTotal: 0,
    NotStarted: 0,
    Users: 1,
  },
  {
    Completed: 0,
    CompletedTotal: 1,
    Date: "2023-06-20",
    InProgress: 0,
    InProgressTotal: 0,
    NotStarted: 0,
    Users: 0,
  },
  {
    Completed: 0,
    CompletedTotal: 1,
    Date: "2023-06-21",
    InProgress: 0,
    InProgressTotal: 0,
    NotStarted: 0,
    Users: 0,
  },
  {
    Completed: 1,
    CompletedTotal: 2,
    Date: "2023-06-22",
    InProgress: 0,
    InProgressTotal: 0,
    NotStarted: 0,
    Users: 1,
  },
  {
    Completed: 0,
    CompletedTotal: 2,
    Date: "2023-06-23",
    InProgress: 1,
    InProgressTotal: 1,
    NotStarted: 0,
    Users: 1,
  },
  {
    Completed: 0,
    CompletedTotal: 2,
    Date: "2023-06-24",
    InProgress: 0,
    InProgressTotal: 1,
    NotStarted: 0,
    Users: 0,
  },
  {
    Completed: 0,
    CompletedTotal: 2,
    Date: "2023-06-25",
    InProgress: 0,
    InProgressTotal: 1,
    NotStarted: 0,
    Users: 0,
  },
  {
    Completed: 1,
    CompletedTotal: 3,
    Date: "2023-06-26",
    InProgress: 1,
    InProgressTotal: 2,
    NotStarted: 0,
    Users: 1,
  },
  {
    Completed: 0,
    CompletedTotal: 3,
    Date: "2023-06-27",
    InProgress: 0,
    InProgressTotal: 2,
    NotStarted: 0,
    Users: 0,
  },
];

export const mockFlowEngagement: FlowCards = {
  Customers: 10,
  AverageCustomerSize: 20,
  AverageFlowsUser: 30,
  Flows: 40,
  Users: 50,
  InProgress: 60,
  Completed: 70,
};

export const mockFlowEmptyLine: AssetActivityCounts = {
  Completed: 0,
  CompletedTotal: 0,
  Date: "2023-06-18",
  InProgress: 0,
  InProgressTotal: 0,
  NotStarted: 0,
  Users: 0,
};

export const mockFlowEmptyEngagement: FlowCards = {
  Customers: 0,
  AverageCustomerSize: 0,
  AverageFlowsUser: 0,
  Flows: 0,
  Users: 0,
  InProgress: 0,
  Completed: 0,
};

export const mockFlowDetails: FlowDetails[] = [
  {
    Completed: 1,
    FlowId: 1056098,
    Goal: "Remediation",
    InProgress: 0,
    LastActivity: "2023-06-19T13:28:56.024",
    NotStarted: 0,
    Status: "Published",
    Title: "ewqeqwewqe",
    Users: 1,
  },
];

export const mockBarData: DataPoint[] = [
  {
    id: starts.toLowerCase(),
    category: starts,
    value: 0,
    fillColor: startsColor,
  },
  {
    id: completes.toLowerCase(),
    category: completes,
    value: 0,
    fillColor: completesColor,
  },
];

export const mockCardData: CardReportingItem[] = [
  {
    stat: 0,
    statDescription: "Customers",
  },
  {
    stat: roundToTwoDigits(0),
    statDescription: "Avg. Customer Size",
  },
  {
    stat: 0,
    statDescription: "Flows",
  },
  {
    stat: 0,
    statDescription: "Users",
  },
  {
    stat: roundToTwoDigits(0),
    statDescription: "Avg. Flows/User",
  },
];

export const mockVideoDailyData: VideoActivityCounts[] = [
  {
    Date: "2023-07-27",
    Events: {
      CompletedCount: 24,
      CumulativeCompletedCount: 27,
      CumulativeStartedCount: 82,
      NotStartedCount: 34,
    },
  },
  {
    Date: "2023-07-28",
    Events: {
      CompletedCount: 61,
      CumulativeCompletedCount: 44,
      CumulativeStartedCount: 70,
      NotStartedCount: 12,
    },
  },
  {
    Date: "2023-07-29",
    Events: {
      CompletedCount: 96,
      CumulativeCompletedCount: 3,
      CumulativeStartedCount: 17,
      NotStartedCount: 71,
    },
  },
  {
    Date: "2023-07-30",
    Events: {
      CompletedCount: 43,
      CumulativeCompletedCount: 76,
      CumulativeStartedCount: 80,
      NotStartedCount: 33,
    },
  },
  {
    Date: "2023-07-31",
    Events: {
      CompletedCount: 26,
      CumulativeCompletedCount: 46,
      CumulativeStartedCount: 38,
      NotStartedCount: 94,
    },
  },
  {
    Date: "2023-08-01",
    Events: {
      CompletedCount: 84,
      CumulativeCompletedCount: 52,
      CumulativeStartedCount: 74,
      NotStartedCount: 83,
    },
  },
  {
    Date: "2023-08-02",
    Events: {
      CompletedCount: 16,
      CumulativeCompletedCount: 60,
      CumulativeStartedCount: 22,
      NotStartedCount: 98,
    },
  },
  {
    Date: "2023-08-03",
    Events: {
      CompletedCount: 35,
      CumulativeCompletedCount: 15,
      CumulativeStartedCount: 2,
      NotStartedCount: 99,
    },
  },
  {
    Date: "2023-08-04",
    Events: {
      CompletedCount: 43,
      CumulativeCompletedCount: 93,
      CumulativeStartedCount: 70,
      NotStartedCount: 11,
    },
  },
  {
    Date: "2023-08-05",
    Events: {
      CompletedCount: 94,
      CumulativeCompletedCount: 13,
      CumulativeStartedCount: 28,
      NotStartedCount: 26,
    },
  },
  {
    Date: "2023-08-06",
    Events: {
      CompletedCount: 99,
      CumulativeCompletedCount: 22,
      CumulativeStartedCount: 91,
      NotStartedCount: 55,
    },
  },
  {
    Date: "2023-08-07",
    Events: {
      CompletedCount: 19,
      CumulativeCompletedCount: 74,
      CumulativeStartedCount: 72,
      NotStartedCount: 83,
    },
  },
  {
    Date: "2023-08-08",
    Events: {
      CompletedCount: 41,
      CumulativeCompletedCount: 20,
      CumulativeStartedCount: 77,
      NotStartedCount: 32,
    },
  },
  {
    Date: "2023-08-09",
    Events: {
      CompletedCount: 85,
      CumulativeCompletedCount: 4,
      CumulativeStartedCount: 37,
      NotStartedCount: 89,
    },
  },
  {
    Date: "2023-08-10",
    Events: {
      CompletedCount: 28,
      CumulativeCompletedCount: 30,
      CumulativeStartedCount: 8,
      NotStartedCount: 85,
    },
  },
  {
    Date: "2023-08-11",
    Events: {
      CompletedCount: 14,
      CumulativeCompletedCount: 16,
      CumulativeStartedCount: 68,
      NotStartedCount: 2,
    },
  },
  {
    Date: "2023-08-12",
    Events: {
      CompletedCount: 22,
      CumulativeCompletedCount: 77,
      CumulativeStartedCount: 95,
      NotStartedCount: 6,
    },
  },
  {
    Date: "2023-08-13",
    Events: {
      CompletedCount: 39,
      CumulativeCompletedCount: 96,
      CumulativeStartedCount: 51,
      NotStartedCount: 95,
    },
  },
  {
    Date: "2023-08-14",
    Events: {
      CompletedCount: 37,
      CumulativeCompletedCount: 49,
      CumulativeStartedCount: 25,
      NotStartedCount: 40,
    },
  },
  {
    Date: "2023-08-15",
    Events: {
      CompletedCount: 96,
      CumulativeCompletedCount: 28,
      CumulativeStartedCount: 74,
      NotStartedCount: 66,
    },
  },
  {
    Date: "2023-08-16",
    Events: {
      CompletedCount: 53,
      CumulativeCompletedCount: 18,
      CumulativeStartedCount: 82,
      NotStartedCount: 73,
    },
  },
  {
    Date: "2023-08-17",
    Events: {
      CompletedCount: 6,
      CumulativeCompletedCount: 3,
      CumulativeStartedCount: 49,
      NotStartedCount: 23,
    },
  },
  {
    Date: "2023-08-18",
    Events: {
      CompletedCount: 35,
      CumulativeCompletedCount: 1,
      CumulativeStartedCount: 44,
      NotStartedCount: 91,
    },
  },
  {
    Date: "2023-08-19",
    Events: {
      CompletedCount: 92,
      CumulativeCompletedCount: 39,
      CumulativeStartedCount: 60,
      NotStartedCount: 99,
    },
  },
  {
    Date: "2023-08-20",
    Events: {
      CompletedCount: 98,
      CumulativeCompletedCount: 81,
      CumulativeStartedCount: 22,
      NotStartedCount: 24,
    },
  },
  {
    Date: "2023-08-21",
    Events: {
      CompletedCount: 66,
      CumulativeCompletedCount: 49,
      CumulativeStartedCount: 21,
      NotStartedCount: 77,
    },
  },
  {
    Date: "2023-08-22",
    Events: {
      CompletedCount: 100,
      CumulativeCompletedCount: 91,
      CumulativeStartedCount: 69,
      NotStartedCount: 18,
    },
  },
  {
    Date: "2023-08-23",
    Events: {
      CompletedCount: 16,
      CumulativeCompletedCount: 75,
      CumulativeStartedCount: 29,
      NotStartedCount: 8,
    },
  },
  {
    Date: "2023-08-24",
    Events: {
      CompletedCount: 63,
      CumulativeCompletedCount: 93,
      CumulativeStartedCount: 28,
      NotStartedCount: 82,
    },
  },
  {
    Date: "2023-08-25",
    Events: {
      CompletedCount: 85,
      CumulativeCompletedCount: 4,
      CumulativeStartedCount: 62,
      NotStartedCount: 80,
    },
  },
  {
    Date: "2023-08-26",
    Events: {
      CompletedCount: 83,
      CumulativeCompletedCount: 41,
      CumulativeStartedCount: 78,
      NotStartedCount: 95,
    },
  },
  {
    Date: "2023-08-27",
    Events: {
      CompletedCount: 68,
      CumulativeCompletedCount: 40,
      CumulativeStartedCount: 41,
      NotStartedCount: 80,
    },
  },
];

export const mockVideoDailyEmptyData: VideoActivityCounts[] = [
  {
    Date: "2023-07-27",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeStartedCount: 0,
      NotStartedCount: 0,
    },
  },
];

export const mockVideoEngagement: VideoEngagement = {
  CompletedCount: 54,
  StartedCount: 22,
};

export const mockVideoDetails: VideoDetails[] = [
  {
    VideoId: 55925,
    VideoTitle: "Video Title 1",
    LastActivity: "01/01/2011 01:11",
    TotalStarts: 62,
    TotalCompletes: 95,
    CompletionRate: 0.982345,
    VideoDuration: 120226,
  },
  {
    VideoId: 8,
    VideoTitle: "Video Title 2",
    LastActivity: "02/02/2012 02:12",
    TotalStarts: 88,
    TotalCompletes: 48,
    CompletionRate: 0.71,
    VideoDuration: 91395,
  },
  {
    VideoId: 62,
    VideoTitle: "Video Title 3",
    LastActivity: "03/03/2013 03:13",
    TotalStarts: 93,
    TotalCompletes: 13,
    CompletionRate: 0.613,
    VideoDuration: 99307,
  },
  {
    VideoId: 32,
    VideoTitle: "Video Title 4",
    LastActivity: "04/04/2014 04:14",
    TotalStarts: 54,
    TotalCompletes: 51,
    CompletionRate: 0.322,
    VideoDuration: 8,
  },
  {
    VideoId: 24,
    VideoTitle: "Video Title 5",
    LastActivity: "05/05/2015 05:15",
    TotalStarts: 30,
    TotalCompletes: 77,
    CompletionRate: 0.022345,
    VideoDuration: 952,
  },
  {
    VideoId: 64,
    VideoTitle: "Video Title 6",
    LastActivity: "06/06/2016 06:16",
    TotalStarts: 59,
    TotalCompletes: 33,
    CompletionRate: 0.242,
    VideoDuration: 27034,
  },
  {
    VideoId: 11,
    VideoTitle: "Video Title 7",
    LastActivity: "07/07/2017 07:17",
    TotalStarts: 30,
    TotalCompletes: 77,
    CompletionRate: 0.022345,
    VideoDuration: 120240,
  },
  {
    VideoId: 34,
    VideoTitle: "Video Title 8",
    LastActivity: "08/08/2018 08:18",
    TotalStarts: 59,
    TotalCompletes: 33,
    CompletionRate: 0.58,
    VideoDuration: 117972,
  },
  {
    VideoId: 75,
    VideoTitle: "Video Title 9",
    LastActivity: "09/09/2019 09:19",
    TotalStarts: 30,
    TotalCompletes: 77,
    CompletionRate: 0.022345,
    VideoDuration: 22366,
  },
  {
    VideoId: 74,
    VideoTitle: "Video Title 10",
    LastActivity: "10/10/2020 10:20",
    TotalStarts: 89,
    TotalCompletes: 8,
    CompletionRate: 0.752,
    VideoDuration: 64,
  },
  {
    VideoId: 82,
    VideoTitle: "Video Title 11",
    LastActivity: "11/11/2021 11:21",
    TotalStarts: 30,
    TotalCompletes: 77,
    CompletionRate: 0.022345,
    VideoDuration: 142549,
  },
  {
    VideoId: 96,
    VideoTitle: "Video Title 12",
    LastActivity: "12/12/2022 12:22",
    TotalStarts: 47,
    TotalCompletes: 13,
    CompletionRate: 0.93234,
    VideoDuration: 57,
  },
];

export const mockAssessmentActivity: AssessmentActivityCounts[] = [
  {
    Date: "2023-07-27",
    Completed: 60,
    CumulativeCompleted: 84,
    Started: 45,
    CumulativeStarted: 3,
    AssessmentId: -1,
  },
  {
    Date: "2023-07-28",
    Completed: 24,
    CumulativeCompleted: 12,
    Started: 59,
    CumulativeStarted: 53,
    AssessmentId: -1,
  },
  {
    Date: "2023-07-29",
    Completed: 54,
    CumulativeCompleted: 100,
    Started: 88,
    CumulativeStarted: 61,
    AssessmentId: -1,
  },
  {
    Date: "2023-07-30",
    Completed: 76,
    CumulativeCompleted: 58,
    Started: 58,
    CumulativeStarted: 79,
    AssessmentId: -1,
  },
  {
    Date: "2023-07-31",
    Completed: 41,
    CumulativeCompleted: 6,
    Started: 40,
    CumulativeStarted: 59,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-01",
    Completed: 83,
    CumulativeCompleted: 1,
    Started: 4,
    CumulativeStarted: 15,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-02",
    Completed: 17,
    CumulativeCompleted: 29,
    Started: 25,
    CumulativeStarted: 92,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-03",
    Completed: 19,
    CumulativeCompleted: 85,
    Started: 32,
    CumulativeStarted: 15,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-04",
    Completed: 37,
    CumulativeCompleted: 6,
    Started: 40,
    CumulativeStarted: 3,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-05",
    Completed: 94,
    CumulativeCompleted: 13,
    Started: 43,
    CumulativeStarted: 6,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-06",
    Completed: 83,
    CumulativeCompleted: 36,
    Started: 73,
    CumulativeStarted: 77,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-07",
    Completed: 49,
    CumulativeCompleted: 99,
    Started: 14,
    CumulativeStarted: 5,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-08",
    Completed: 14,
    CumulativeCompleted: 17,
    Started: 8,
    CumulativeStarted: 15,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-09",
    Completed: 75,
    CumulativeCompleted: 97,
    Started: 4,
    CumulativeStarted: 77,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-10",
    Completed: 45,
    CumulativeCompleted: 45,
    Started: 28,
    CumulativeStarted: 30,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-11",
    Completed: 42,
    CumulativeCompleted: 15,
    Started: 16,
    CumulativeStarted: 27,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-12",
    Completed: 58,
    CumulativeCompleted: 11,
    Started: 8,
    CumulativeStarted: 44,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-13",
    Completed: 62,
    CumulativeCompleted: 34,
    Started: 24,
    CumulativeStarted: 1,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-14",
    Completed: 23,
    CumulativeCompleted: 75,
    Started: 48,
    CumulativeStarted: 76,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-15",
    Completed: 92,
    CumulativeCompleted: 92,
    Started: 95,
    CumulativeStarted: 30,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-16",
    Completed: 66,
    CumulativeCompleted: 48,
    Started: 38,
    CumulativeStarted: 50,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-17",
    Completed: 28,
    CumulativeCompleted: 76,
    Started: 23,
    CumulativeStarted: 42,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-18",
    Completed: 95,
    CumulativeCompleted: 8,
    Started: 47,
    CumulativeStarted: 29,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-19",
    Completed: 19,
    CumulativeCompleted: 3,
    Started: 88,
    CumulativeStarted: 96,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-20",
    Completed: 87,
    CumulativeCompleted: 100,
    Started: 69,
    CumulativeStarted: 37,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-21",
    Completed: 42,
    CumulativeCompleted: 65,
    Started: 51,
    CumulativeStarted: 86,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-22",
    Completed: 9,
    CumulativeCompleted: 34,
    Started: 15,
    CumulativeStarted: 92,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-23",
    Completed: 71,
    CumulativeCompleted: 93,
    Started: 51,
    CumulativeStarted: 29,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-24",
    Completed: 38,
    CumulativeCompleted: 87,
    Started: 4,
    CumulativeStarted: 93,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-25",
    Completed: 52,
    CumulativeCompleted: 9,
    Started: 55,
    CumulativeStarted: 27,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-26",
    Completed: 78,
    CumulativeCompleted: 11,
    Started: 6,
    CumulativeStarted: 95,
    AssessmentId: -1,
  },
  {
    Date: "2023-08-27",
    Completed: 92,
    CumulativeCompleted: 54,
    Started: 43,
    CumulativeStarted: 8,
    AssessmentId: -1,
  },
];

export const mockAssessmentActivityEmpty: AssessmentActivityCounts[] = [
  {
    CumulativeCompleted: 0,
    Completed: 0,
    Date: "2023-07-27",
    CumulativeStarted: 0,
    Started: 0,
    AssessmentId: -1,
  },
];

export const mockAssessmentEngagement: AssessmentEngagement = {
  Started: 70,
  Completed: 85,
};

export const mockAssessmentCards: AssessmentCards = {
  Customers: 96,
  Completed: 16,
  CompletionRate: 50,
  AverageTime: 96.56,
  AverageGrade: 90.34523,
};

export const mockAssessmentDetails: AssessmentDetails[] = [
  {
    AssessmentId: 33607,
    AssessmentTitle: "Assessment 1",
    LastActivity: "01/01/2023 01:01",
    TotalStarts: 63,
    TotalCompletes: 27,
    CompletionRate: 71.1435432,
    AverageTime: 93,
    AverageGrade: 25.245,
  },
  {
    AssessmentId: 89,
    AssessmentTitle: "Assessment 2",
    LastActivity: "02/02/2023 02:02",
    TotalStarts: 67,
    TotalCompletes: 52,
    CompletionRate: 31.4,
    AverageTime: 17,
    AverageGrade: 69.13,
  },
  {
    AssessmentId: 92,
    AssessmentTitle: "Assessment 3",
    LastActivity: "03/03/2023 03:03",
    TotalStarts: 14,
    TotalCompletes: 51,
    CompletionRate: 22,
    AverageTime: 5420,
    AverageGrade: 2.3,
  },
  {
    AssessmentId: 24,
    AssessmentTitle: "Assessment 4",
    LastActivity: "04/04/2023 04:04",
    TotalStarts: 100,
    TotalCompletes: 27,
    CompletionRate: 59,
    AverageTime: 58,
    AverageGrade: 23,
  },
  {
    AssessmentId: 1,
    AssessmentTitle: "Assessment 5",
    LastActivity: "05/05/2023 05:05",
    TotalStarts: 93,
    TotalCompletes: 90,
    CompletionRate: 44,
    AverageTime: 50,
    AverageGrade: 98,
  },
  {
    AssessmentId: 10,
    AssessmentTitle: "Assessment 6",
    LastActivity: "06/06/2023 06:06",
    TotalStarts: 59,
    TotalCompletes: 9,
    CompletionRate: 22,
    AverageTime: 87,
    AverageGrade: 20,
  },
  {
    AssessmentId: 61,
    AssessmentTitle: "Assessment 7",
    LastActivity: "07/07/2023 07:07",
    TotalStarts: 94,
    TotalCompletes: 63,
    CompletionRate: 55,
    AverageTime: 83,
    AverageGrade: 81,
  },
  {
    AssessmentId: 98,
    AssessmentTitle: "Assessment 8",
    LastActivity: "08/08/2023 08:08",
    TotalStarts: 69,
    TotalCompletes: 50,
    CompletionRate: 45,
    AverageTime: 85,
    AverageGrade: 61,
  },
  {
    AssessmentId: 99,
    AssessmentTitle: "Assessment 9",
    LastActivity: "09/09/2023 09:09",
    TotalStarts: 7,
    TotalCompletes: 14,
    CompletionRate: 71,
    AverageTime: 93,
    AverageGrade: 67,
  },
  {
    AssessmentId: 44,
    AssessmentTitle: "Assessment 10",
    LastActivity: "10/10/2023 10:10",
    TotalStarts: 45,
    TotalCompletes: 22,
    CompletionRate: 92,
    AverageTime: 50,
    AverageGrade: 24,
  },
  {
    AssessmentId: 4,
    AssessmentTitle: "Assessment 11",
    LastActivity: "11/11/2023 11:11",
    TotalStarts: 48,
    TotalCompletes: 6,
    CompletionRate: 91,
    AverageTime: 10,
    AverageGrade: 61,
  },
  {
    AssessmentId: 33,
    AssessmentTitle: "Assessment 12",
    LastActivity: "12/12/2023 12:12",
    TotalStarts: 23,
    TotalCompletes: 90,
    CompletionRate: 9,
    AverageTime: 63,
    AverageGrade: 74,
  },
  {
    AssessmentId: 29,
    AssessmentTitle: "Assessment 13",
    LastActivity: "12/13/2023 12:13",
    TotalStarts: 26,
    TotalCompletes: 17,
    CompletionRate: 78,
    AverageTime: 83,
    AverageGrade: 98,
  },
];

export const mockSurveyActivity: SurveyActivityCounts[] = [
  {
    Date: "2023-07-27",
    Events: {
      CompletedCount: 76,
      CumulativeCompletedCount: 4,
    },
  },
  {
    Date: "2023-07-28",
    Events: {
      CompletedCount: 12,
      CumulativeCompletedCount: 5,
    },
  },
  {
    Date: "2023-07-29",
    Events: {
      CompletedCount: 37,
      CumulativeCompletedCount: 41,
    },
  },
  {
    Date: "2023-07-30",
    Events: {
      CompletedCount: 74,
      CumulativeCompletedCount: 33,
    },
  },
  {
    Date: "2023-07-31",
    Events: {
      CompletedCount: 48,
      CumulativeCompletedCount: 15,
    },
  },
  {
    Date: "2023-08-01",
    Events: {
      CompletedCount: 20,
      CumulativeCompletedCount: 55,
    },
  },
  {
    Date: "2023-08-02",
    Events: {
      CompletedCount: 41,
      CumulativeCompletedCount: 46,
    },
  },
  {
    Date: "2023-08-03",
    Events: {
      CompletedCount: 96,
      CumulativeCompletedCount: 97,
    },
  },
  {
    Date: "2023-08-04",
    Events: {
      CompletedCount: 18,
      CumulativeCompletedCount: 48,
    },
  },
  {
    Date: "2023-08-05",
    Events: {
      CompletedCount: 98,
      CumulativeCompletedCount: 89,
    },
  },
  {
    Date: "2023-08-06",
    Events: {
      CompletedCount: 11,
      CumulativeCompletedCount: 74,
    },
  },
  {
    Date: "2023-08-07",
    Events: {
      CompletedCount: 23,
      CumulativeCompletedCount: 44,
    },
  },
  {
    Date: "2023-08-08",
    Events: {
      CompletedCount: 49,
      CumulativeCompletedCount: 46,
    },
  },
  {
    Date: "2023-08-09",
    Events: {
      CompletedCount: 52,
      CumulativeCompletedCount: 37,
    },
  },
  {
    Date: "2023-08-10",
    Events: {
      CompletedCount: 28,
      CumulativeCompletedCount: 71,
    },
  },
  {
    Date: "2023-08-11",
    Events: {
      CompletedCount: 7,
      CumulativeCompletedCount: 11,
    },
  },
  {
    Date: "2023-08-12",
    Events: {
      CompletedCount: 7,
      CumulativeCompletedCount: 76,
    },
  },
  {
    Date: "2023-08-13",
    Events: {
      CompletedCount: 62,
      CumulativeCompletedCount: 67,
    },
  },
  {
    Date: "2023-08-14",
    Events: {
      CompletedCount: 23,
      CumulativeCompletedCount: 38,
    },
  },
  {
    Date: "2023-08-15",
    Events: {
      CompletedCount: 17,
      CumulativeCompletedCount: 44,
    },
  },
  {
    Date: "2023-08-16",
    Events: {
      CompletedCount: 60,
      CumulativeCompletedCount: 60,
    },
  },
  {
    Date: "2023-08-17",
    Events: {
      CompletedCount: 100,
      CumulativeCompletedCount: 14,
    },
  },
  {
    Date: "2023-08-18",
    Events: {
      CompletedCount: 51,
      CumulativeCompletedCount: 77,
    },
  },
  {
    Date: "2023-08-19",
    Events: {
      CompletedCount: 27,
      CumulativeCompletedCount: 84,
    },
  },
  {
    Date: "2023-08-20",
    Events: {
      CompletedCount: 60,
      CumulativeCompletedCount: 23,
    },
  },
  {
    Date: "2023-08-21",
    Events: {
      CompletedCount: 95,
      CumulativeCompletedCount: 82,
    },
  },
  {
    Date: "2023-08-22",
    Events: {
      CompletedCount: 98,
      CumulativeCompletedCount: 30,
    },
  },
  {
    Date: "2023-08-23",
    Events: {
      CompletedCount: 6,
      CumulativeCompletedCount: 29,
    },
  },
  {
    Date: "2023-08-24",
    Events: {
      CompletedCount: 87,
      CumulativeCompletedCount: 82,
    },
  },
  {
    Date: "2023-08-25",
    Events: {
      CompletedCount: 12,
      CumulativeCompletedCount: 40,
    },
  },
  {
    Date: "2023-08-26",
    Events: {
      CompletedCount: 65,
      CumulativeCompletedCount: 65,
    },
  },
  {
    Date: "2023-08-27",
    Events: {
      CompletedCount: 95,
      CumulativeCompletedCount: 72,
    },
  },
];

export const mockSurveyActivityEmpty: SurveyActivityCounts[] = [
  {
    Date: "2023-07-27",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
    },
  },
];

export const mockSurveyEngagement: SurveyEngagement = {
  StartedCount: 7,
  CompletedCount: 75,
};

export const mockSurveyCards: SurveyCards = {
  AverageTime: 41,
  CompletionRate: 45,
  Completions: 3,
  Customers: 24,
  Started: 10,
};

export const mockSurveyDetails: SurveyDetails[] = [
  {
    SurveyId: 40801,
    SurveyTitle: "Survey 1",
    LastActivity: "01/01/2023 01:01",
    TotalStarts: 3,
    TotalCompletes: 11,
    CompletionRate: 48.1451,
    AverageTime: 3,
  },
  {
    SurveyId: 1,
    SurveyTitle: "Survey 2",
    LastActivity: "02/02/2023 02:02",
    TotalStarts: 22,
    TotalCompletes: 8,
    CompletionRate: 29.56,
    AverageTime: 69,
  },
  {
    SurveyId: 59,
    SurveyTitle: "Survey 3",
    LastActivity: "03/03/2023 03:03",
    TotalStarts: 95,
    TotalCompletes: 84,
    CompletionRate: 9.3,
    AverageTime: 5420,
  },
  {
    SurveyId: 2,
    SurveyTitle: "Survey 4",
    LastActivity: "04/04/2023 04:04",
    TotalStarts: 75,
    TotalCompletes: 6,
    CompletionRate: 71,
    AverageTime: 31,
  },
  {
    SurveyId: 40,
    SurveyTitle: "Survey 5",
    LastActivity: "05/05/2023 05:05",
    TotalStarts: 78,
    TotalCompletes: 27,
    CompletionRate: 67,
    AverageTime: 16,
  },
  {
    SurveyId: 81,
    SurveyTitle: "Survey 6",
    LastActivity: "06/06/2023 06:06",
    TotalStarts: 28,
    TotalCompletes: 59,
    CompletionRate: 23,
    AverageTime: 12,
  },
  {
    SurveyId: 68,
    SurveyTitle: "Survey 7",
    LastActivity: "07/07/2023 07:07",
    TotalStarts: 22,
    TotalCompletes: 91,
    CompletionRate: 55,
    AverageTime: 50,
  },
  {
    SurveyId: 93,
    SurveyTitle: "Survey 8",
    LastActivity: "08/08/2023 08:08",
    TotalStarts: 68,
    TotalCompletes: 81,
    CompletionRate: 77,
    AverageTime: 89,
  },
  {
    SurveyId: 76,
    SurveyTitle: "Survey 9",
    LastActivity: "09/09/2023 09:09",
    TotalStarts: 82,
    TotalCompletes: 14,
    CompletionRate: 34,
    AverageTime: 47,
  },
  {
    SurveyId: 74,
    SurveyTitle: "Survey 10",
    LastActivity: "10/10/2023 10:10",
    TotalStarts: 6,
    TotalCompletes: 95,
    CompletionRate: 70,
    AverageTime: 74,
  },
  {
    SurveyId: 99,
    SurveyTitle: "Survey 11",
    LastActivity: "11/11/2023 11:11",
    TotalStarts: 70,
    TotalCompletes: 92,
    CompletionRate: 10,
    AverageTime: 51,
  },
  {
    SurveyId: 25,
    SurveyTitle: "Survey 12",
    LastActivity: "12/12/2023 12:12",
    TotalStarts: 5,
    TotalCompletes: 41,
    CompletionRate: 5,
    AverageTime: 25,
  },
  {
    SurveyId: 63,
    SurveyTitle: "Survey 13",
    LastActivity: "12/13/2023 12:13",
    TotalStarts: 64,
    TotalCompletes: 45,
    CompletionRate: 30,
    AverageTime: 47,
  },
];

export const mockPdfDailyData: EngagementLineData[] = [
  {
    Date: "2023-07-02T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-03T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-04T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-05T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-06T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-07T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-08T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-09T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-10T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-11T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-12T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-13T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-14T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-15T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-16T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-17T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-18T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-19T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 0,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-20T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 3,
      NotStartedCount: 3,
    },
  },
  {
    Date: "2023-07-21T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 3,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-22T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 3,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-23T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 3,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-24T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 3,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-25T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 3,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-26T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 3,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-27T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 6,
      NotStartedCount: 3,
    },
  },
  {
    Date: "2023-07-28T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 6,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-29T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 6,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-30T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 6,
      NotStartedCount: 0,
    },
  },
  {
    Date: "2023-07-31T00:00:00.000",
    Events: {
      CompletedCount: 0,
      CumulativeCompletedCount: 0,
      CumulativeNotStartedCount: 6,
      NotStartedCount: 0,
    },
  },
];

export const mockPdfEngagementData: EngagementHorizontalData = {
  CompletedCount: 10,
  NotStartedCount: 5,
};

export const mockEmptyPdfEngagementData: EngagementHorizontalData = {
  CompletedCount: 0,
  NotStartedCount: 0,
};

export const mockPdfDetailsData: PdfDetails[] = [
  {
    CompletedCount: 0,
    LastActivityDate: "2023-07-31",
    NotStartedCount: 1,
    PdfId: 1,
    PdfTitle: "13july for purchasing",
  },
  {
    CompletedCount: 0,
    LastActivityDate: "2023-07-31",
    NotStartedCount: 1,
    PdfId: 2,
    PdfTitle: "13july for purchasing",
  },
  {
    CompletedCount: 0,
    LastActivityDate: "2023-07-31",
    NotStartedCount: 0,
    PdfId: 3,
    PdfTitle: "345345tret",
  },
  {
    CompletedCount: 0,
    LastActivityDate: "2023-07-09",
    NotStartedCount: 0,
    PdfId: 4,
    PdfTitle: "Clean.Code.A.Handbook.of.Agile.Software.Craftsmanship",
  },
  {
    CompletedCount: 0,
    LastActivityDate: "2023-07-17",
    NotStartedCount: 0,
    PdfId: 5,
    PdfTitle: "DEMO Performance",
  },
];

export const mockEventsTableData: EventsTableEntity[] = [
  {
    EventId: 1,
    EventTitle: "Event 1",
    Registered: 50,
    Attended: 40,
    NotAttended: 10,
    LastActivity: "2024-02-05 15:28:13",
  },
  {
    EventId: 2,
    EventTitle: "Event 2",
    Registered: 30,
    Attended: 25,
    NotAttended: 5,
    LastActivity: "2024-02-06 10:15:22",
  },
  {
    EventId: 3,
    EventTitle: "Event 3",
    Registered: 20,
    Attended: 15,
    NotAttended: 5,
    LastActivity: "2024-02-07 14:45:01",
  },
  {
    EventId: 4,
    EventTitle: "Event 4",
    Registered: 60,
    Attended: 55,
    NotAttended: 5,
    LastActivity: "2024-02-08 09:30:18",
  },
  {
    EventId: 5,
    EventTitle: "Event 5",
    Registered: 80,
    Attended: 70,
    NotAttended: 10,
    LastActivity: "2024-02-09 16:55:42",
  },
];
