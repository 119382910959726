import { faCodeBranch, faSortDown, faSortUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { RouteNames } from "enums";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { FC } from "react";
import { ColumnOption } from "../../../../interfaces/columnOptions";

import { PerformanceFilter, completions, inProgress } from "features/Library/Common/utils/performanceUtils";
import styles from "./trendingTable.module.scss";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import moboConfigurator from "../../../../moboConfigurator";

export type TrendingFlow = {
  id: number;
  FlowId: number;
  FlowTitle: string;
  FlowStatus: "Published" | "Draft";
  Users: number;
  InProgress: number;
  Completes: number;
  Trending: number;
  TrendingLastMonth: number;
  TrendingChange: "Up" | "Down" | "No Change";
};

export interface TrendingFlowsProps {
  trendingFlows: TrendingFlow[];
  filter: PerformanceFilter;
}

const columnOptions: ColumnOption<TrendingFlow>[] = [
  {
    name: "THIS MONTH",
    width: 2,
    render: (entity) => {
      const icon = entity.TrendingChange === "Down" ? faSortDown : faSortUp;
      return (
        <div
          className={cn(styles.currentlyTrending, {
            [styles.downTrend]: entity.TrendingChange === "Down",
            [styles.upTrend]: entity.TrendingChange === "Up",
            [styles.staticTrend]: entity.TrendingChange === "No Change",
          })}
        >
          <div>{entity.Trending.toLocaleString()}</div>
          <FontAwesomeIcon icon={icon} />
        </div>
      );
    },
    isSortable: false,
  },
  {
    render: (entity) => {
      return (
        <div className={styles.title}>
          <FontAwesomeIcon icon={faCodeBranch} />
          <Tooltip target={
            <TextTruncate>
              <a href={moboConfigurator.appendMoboIdToUrl(`/content/flows/${entity.FlowId}`)}>{entity.FlowTitle}</a>
            </TextTruncate>
          }
            content={entity.FlowTitle} />
        </div>
      );
    },
    name: "FLOW",
    isSortable: false,
    width: 4,
  },
  {
    name: "TOTAL USERS",
    width: 3,
    isSortable: false,
    render: (entity) => {
      return <div>{entity.Users.toLocaleString()}</div>;
    },
  },
  {
    name: inProgress.toUpperCase(),
    width: 3,
    isSortable: false,
    render: (entity) => {
      return <div>{entity.InProgress.toLocaleString()}</div>;
    },
  },
  {
    name: completions.toUpperCase(),
    width: 3,
    isSortable: false,
    render: (entity) => {
      return <div>{entity.Completes.toLocaleString()}</div>;
    },
  },
  {
    name: "LAST MONTH",
    width: 2,
    isSortable: false,
    render: (entity) => {
      return <div className={styles.previouslyTrending}>{entity.TrendingLastMonth.toLocaleString()}</div>;
    },
  },
];

export const TrendingFlows: FC<TrendingFlowsProps> = (props) => {
  const { trendingFlows, filter } = props;

  return (
    <>
      <a className={cn(styles.root, styles.trendingFlows)} href={`${RouteNames.contentFlows}`}>
        More Details
      </a>
      <GenericPerformanceList
        hideControls
        rows={trendingFlows}
        columns={columnOptions}
        title="Trending Flows"
        filter={filter}
      />
    </>
  );
};
