import PropTypes from "prop-types";
import cn from "classnames";
import { Link } from "react-router-dom";
import "./selectableCard.scss";
import { Card } from "semantic-ui-react";
import moboConfigurator from "../../../moboConfigurator";

function SelectableCard({ id, children, selected, disabled, className, onCardClick, onSelect, url }) {
  const cardClassNames = cn("selectable-card", className, {
    "no-hover-state": !onCardClick,
    selected: selected,
    disabled: disabled,
    clickableCard: url,
  });

  const onClick = (e) => {
    let targetClassName = e.target.className;
    let parentClassName = e.target.parentElement.className;
    const checkboxClassName = "selection-checkbox";
    const radioButtonClassName = "radio checkbox";
    const btnClassName = "button";

    const containsCheckbox = () => {
      return (
        targetClassName.includes(checkboxClassName) ||
        targetClassName.includes(radioButtonClassName) ||
        parentClassName.includes(checkboxClassName) ||
        parentClassName.includes(radioButtonClassName)
      );
    };

    if (!disabled && containsCheckbox()) {
      onSelect(id);
      e.preventDefault();
      e.stopPropagation();
    } else if (!targetClassName.includes(btnClassName) && !parentClassName.includes(btnClassName)) {
      onCardClick && onCardClick(id);
    }
  };

  if (!url) {
    return (
      <Card className={cardClassNames} key={id} onClick={onClick} data-testid="selectable-card" data-id={id}>
        {children}
      </Card>
    );
  }

  return (
    <Link
      to={moboConfigurator.appendMoboIdToUrl(url)}
      className={`ui card ${cardClassNames}`}
      key={id}
      onClick={onClick}
      data-testid="selectable-card"
      data-id={id}
    >
      {children}
    </Link>
  );
}

SelectableCard.defaultProps = {
  onSelect: (id) => {
    console.warn(`No select handler on card. Card id: ${id}`);
  },
};

SelectableCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onCardClick: PropTypes.func,
  onSelect: PropTypes.func,
  url: PropTypes.string,
};

export default SelectableCard;
